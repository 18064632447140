import React from "react"
import { Flex, Box } from "reflexbox/styled-components"

import styled from "styled-components"

import { SafeArea } from "../SafeArea"
import { ReadyToStart } from "../ReadyToStart"

const Root = styled.footer`
  background: transparent;
  overflow: hidden;
  padding-top: 600px;
  margin-top: -500px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 600px;
    height: 6000px;
    background: #f6f9fc;
    -webkit-transform: skew(0, -12deg);
    transform: skew(0, -12deg);
  }
`

const CTA = styled(Box)`
  border-bottom: 2px solid rgba(207, 215, 223, 0.25);
  position: relative;
`

const FooterContainer = styled(Box)`
  padding: 35px 0;
  color: #8898aa;
`

const Copyright = styled.p`
  text-align: center;
`

interface IFooterProps {
  showCTA?: boolean
}

export const Footer: React.FC<IFooterProps> = props => {
  const { showCTA = true } = props

  const getCurrentYear = () => {
    return new Date().getFullYear()
  }

  return (
    <Root>
      {showCTA && (
        <CTA>
          <ReadyToStart></ReadyToStart>
        </CTA>
      )}
      <FooterContainer>
        <SafeArea>
          <Copyright>&copy; {getCurrentYear()} Slalom, LLC.</Copyright>
        </SafeArea>
      </FooterContainer>
    </Root>
  )
}
