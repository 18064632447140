import React from "react"
import { Flex, Box } from "reflexbox/styled-components"
import { SafeArea } from "../SafeArea"
import styled from "styled-components"

const LaunchButton = styled.button`
  padding: 14px 44px;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  border-radius: 2px;
  border-color: #fff;
  color: #fff;
  background-color: ${props => props.theme.colors.primary[500]};
  cursor: pointer;
  transition: 0.2s cubic-bezier(0, 0, 0.2, 1);
  &:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  }
`

const FooterPhrase = styled.h5`
  font-size: 32px;
  line-height: 1.4;
`

export const ReadyToStart = () => {
  return (
    <SafeArea>
      <Flex
        alignItems="center"
        paddingTop={150}
        paddingBottom={100}
        flexDirection="column"
      >
        <Box textAlign="center">
          <FooterPhrase>Ready to try SparkThink?</FooterPhrase>
        </Box>
        <Box padding={20}>
          <a href="/admin">
            <LaunchButton>Launch SparkThink</LaunchButton>
          </a>
        </Box>
      </Flex>
    </SafeArea>
  )
}
